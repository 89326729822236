





































































































import CommonUtils from '@/utils/common-utils';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Confirm from './../../components/core/Confirm.vue';
import ld from 'lodash';
import WebUtils from '@/utils/web-utils';
import MyListMixin from '@/mixins/mylist-mixin';
import TableResizeMixin from '@/mixins/table-resize-mixin';
import PageLifeCycleMixin from '@/mixins/page-lifecycle-mixin';
import { tableWidths } from '@/constants/index';

const myLists = namespace('mylists');
const settings = namespace('settings');

@Component({
  mixins: [PageLifeCycleMixin, MyListMixin, TableResizeMixin]
})
export default class MyList extends Vue {
  localRefreshKey = CommonUtils.generateUUID();
  localStandards: Array<any> = [];
  localIsEditingStandards = false;
  editMode!: boolean;
  searchText!: string;
  selectedRows!: Array<any>;
  hasSelectedRows!: boolean;
  tableHeight!: string;
  headers = [
    { text: this.$t('idLabel'), value: 'standardId', width: this.tableWidths.shortText, sortable: true },
    { text: this.$t('descriptionLabel'), value: 'standardDescription', sortable: true },
    { width: this.tableWidths.action2, value: 'actions', sortable: false }
  ];

  $refs!: {
    standardsConfirm: Confirm
  }

  @myLists.Action
  updateStandard!: (params?: any) => Promise<any>;

  @myLists.Action
  loadStandards!: () => Promise<any>;

  @myLists.Action
  deleteStandard!: (params: any) => Promise<any>;

  @myLists.Getter
  getAllStandards!: any;

  @myLists.Getter
  getListLoading!: any;

  @settings.Getter('getShowSnackbarNotifications')
  showSnackbarNotifications!: boolean;

  get localListLoading() {
    return this.getListLoading;
  }

  set localListLoading(value: boolean) {
    this.$store.commit('mylists/setListLoading', value);
  }

  get mobileBreakpoint() {
    return CommonUtils.getMobileBreakpointSize();
  }

  get isMobileMode() {
    return CommonUtils.isMobileMode();
  }

  get mobileTableClass() {
    return CommonUtils.mobileTableClass();
  }

  get mobileRowClass() {
    return CommonUtils.mobileRowClass();
  }

  get mobileHeaderClass() {
    return CommonUtils.mobileHeaderClass();
  }

  get mobileCellClass() {
    return CommonUtils.mobileCellClass();
  }

  get contentBreakpoint() {
    return CommonUtils.mainContentBreakpoint();
  }

  get tableWidths() {
    return tableWidths;
  }

  initialize(initialLoad:boolean) {
    initialLoad ? CommonUtils.showLoading() : this.localListLoading = true;
    this.loadStandards().then(() => {
      this.localStandards = this.getAllStandards;
    }).finally(() => {
      this.localRefreshKey = CommonUtils.generateUUID();
      CommonUtils.hideLoading();
      this.localListLoading = false;
    });
  }

  toggleStandardIdEditable(item: any) {
    const standards = ld.cloneDeep(this.localStandards);
    standards.forEach(s => {
      s.editStandardId = false;
    });
    const standard = standards.find(s => s.dbId === item.dbId);
    standard.editStandardId = !standard.editStandardId;
    this.localStandards = standards;
  }

  standardIdEditableBlur() {
    const standards = ld.cloneDeep(this.localStandards);
    standards.forEach(s => {
      s.editStandardId = false;
    });
    this.localStandards = standards;
  }

  toggleEditMode() {
    this.editMode = !this.editMode;
    if (this.editMode === false) {
      this.selectedRows = [];
    }
  }

  addNewStandard() {
    this.$eventBus.$emit('openSubPage', {
      type: 'mylist',
      width: 600,
      modal: this.$currentUser.defaultEditorMode === 'modal',
      input: {
        action: 'add'
      }
    });
    this.localIsEditingStandards = true;
  }

  editSelectedStandard(item: any) {
    this.$eventBus.$emit('openSubPage', {
      type: 'mylist',
      width: 600,
      modal: this.$currentUser.defaultEditorMode === 'modal',
      input: {
        action: 'edit',
        standardObject: item
      }
    });
    this.localIsEditingStandards = true;
  }

  deleteSelectedStandard(standard: any) {
    const that = this;
    this.$refs.standardsConfirm.confirm({
      title: this.$t('listLabel'),
      text: this.$t('deleteListLabel'),
      option1ButtonAlternativeText: this.$t('continueLabel')
    }).then((result) => {
      if (result === 1) {
        this.localListLoading = true;
        const ids: Array<any> = [standard.dbId];
        that.deleteStandard({ dbId: ids }).then(() => {
          this.$eventBus.$emit('deleteList', ids);
          if (this.showSnackbarNotifications) {
            if (this.$currentUser.isTeacher) {
              this.$snotify.success(this.$t('statusMsg19') as string);
            } else {
              this.$snotify.success(this.$t('statusMsg19b') as string);
            }
          }
          that.initialize(false);
        });
      }
    });
  }

  deleteStandards() {
    if (this.hasSelectedRows) {
      const that = this;
      this.$refs.standardsConfirm.confirm({
        title: this.$t('listLabel'),
        text: this.$t('confirmDeleteMultipleGeneral', { type: this.$t('listsLabel') }),
        option1ButtonAlternativeText: this.$t('continueLabel')
      }).then((result) => {
        if (result === 1) {
          this.localListLoading = true;
          const ids = this.selectedRows.map((s: any) => { return s.dbId })
          this.selectedRows = [];
          that.deleteStandard({ dbId: ids }).then(() => {
            this.$eventBus.$emit('deleteList', ids);
            if (this.showSnackbarNotifications) {
              if (this.$currentUser.isTeacher) {
                this.$snotify.success(this.$t('statusMsg19') as string);
              } else {
                this.$snotify.success(this.$t('statusMsg19b') as string);
              }
            }
            that.initialize(false);
          });
        }
      });
    }
  }

  saveStandardId(item: any) {
    const standards = ld.cloneDeep(this.localStandards);
    const standard = standards.find(s => s.dbId === item.dbId);
    this.localListLoading = true;
    this.updateStandard({ dbId: standard.dbId, standardId: standard.standardId, standardDescription: standard.standardDescription }).then(() => {
      this.localListLoading = false;
      if (this.showSnackbarNotifications) {
        if (this.$currentUser.isTeacher) {
          this.$snotify.success(this.$t('statusMsg18') as string);
        } else {
          this.$snotify.success(this.$t('statusMsg18b') as string);
        }
      }
    });
    item.editStandardId = false;
    this.$nextTick();
  }

  doInit(reload: boolean) {
    this.localIsEditingStandards = false;
    if (reload) {
      this.localListLoading = true;
      this.initialize(false);
    }
  }

  onUserModeChanged() {
    this.doInit(true);
  }

  created() {
    this.initialize(true);
    this.$nextTick(() => {
      this.$eventBus.$on('closeStandardEditor', (reload: boolean) => {
        this.doInit(reload);
      });

      this.$eventBus.$on('userModeChanged', this.onUserModeChanged);

      this.$eventBus.$emit('openDefaultSubPage');
    });
  }

  destroyed() {
    this.$eventBus.$off('closeStandardEditor');
    this.$eventBus.$off('userModeChanged', this.onUserModeChanged);
  }

  updated() {
    WebUtils.initMathJax();
  }
}
