var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-sheet",
    { ref: "myListsRef", staticClass: "my-list", attrs: { color: "#ffffff" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "rounded-0",
                  class: { "mt-5": _vm.$vuetify.breakpoint.mdAndUp },
                  attrs: { flat: "" }
                },
                [
                  _c("v-data-table", {
                    ref: "table",
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.localStandards,
                      "item-key": "dbId",
                      search: _vm.searchText,
                      "show-select": _vm.editMode,
                      height: _vm.tableHeight,
                      "fixed-header": "",
                      "hide-default-footer": "",
                      "disable-pagination": "",
                      loading: _vm.localListLoading,
                      "loading-text": _vm.$t("listLoadingMsg"),
                      "mobile-breakpoint": _vm.mobileBreakpoint
                    },
                    on: { "click:row": _vm.editSelectedStandard },
                    scopedSlots: _vm._u([
                      {
                        key: "body",
                        fn: function(ref) {
                          var items = ref.items
                          var isSelected = ref.isSelected
                          var select = ref.select
                          return [
                            _c(
                              "tbody",
                              {
                                ref: "listContainer",
                                staticClass: "list-container"
                              },
                              _vm._l(items, function(item, index) {
                                return _c(
                                  "tr",
                                  {
                                    key: index,
                                    class: _vm.mobileTableClass,
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { tabindex: "0", role: "row" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.editSelectedStandard(item)
                                      },
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.editSelectedStandard(item)
                                      }
                                    }
                                  },
                                  [
                                    _vm.editMode
                                      ? _c(
                                          "td",
                                          {
                                            class: _vm.mobileRowClass,
                                            on: {
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                select(item, !isSelected(item))
                                              }
                                            }
                                          },
                                          [
                                            _vm.isMobileMode
                                              ? _c("div", {
                                                  class: _vm.mobileHeaderClass
                                                })
                                              : _vm._e(),
                                            _c("v-checkbox", {
                                              class: _vm.mobileCellClass,
                                              staticStyle: {
                                                margin: "0px",
                                                padding: "0px"
                                              },
                                              attrs: {
                                                "input-value": isSelected(item),
                                                color: !_vm.$vuetify.theme.dark
                                                  ? "#7a7a7a"
                                                  : "#ffffff",
                                                "hide-details": "",
                                                "aria-label": item.dbId
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  select(
                                                    item,
                                                    !isSelected(item)
                                                  )
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c("td", { class: _vm.mobileRowClass }, [
                                      _vm.isMobileMode
                                        ? _c(
                                            "div",
                                            { class: _vm.mobileHeaderClass },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.headers[0].text)
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      item.editStandardId
                                        ? _c(
                                            "div",
                                            { class: _vm.mobileCellClass },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  autofocus: "",
                                                  "single-line": "",
                                                  "append-icon": "fa fa-check"
                                                },
                                                on: {
                                                  keyup: function($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    return _vm.saveStandardId(
                                                      item
                                                    )
                                                  },
                                                  "click:append": function(
                                                    $event
                                                  ) {
                                                    return _vm.saveStandardId(
                                                      item
                                                    )
                                                  },
                                                  blur:
                                                    _vm.standardIdEditableBlur
                                                },
                                                model: {
                                                  value: item.standardId,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "standardId",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.standardId"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _c(
                                            "div",
                                            {
                                              class: _vm.mobileCellClass,
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.toggleStandardIdEditable(
                                                    item
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(item.standardId))
                                              ]),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "ml-2 hover-edit-icon",
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { "x-small": "" }
                                                    },
                                                    [_vm._v("fal fa-pencil")]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                    ]),
                                    _c(
                                      "td",
                                      {
                                        class: _vm.mobileRowClass,
                                        style: {
                                          "min-width": _vm.tableWidths.longText
                                        }
                                      },
                                      [
                                        _vm.isMobileMode
                                          ? _c(
                                              "div",
                                              { class: _vm.mobileHeaderClass },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.headers[1].text)
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _c("div", {
                                          staticClass: "remove-margin",
                                          class: _vm.mobileCellClass,
                                          domProps: {
                                            innerHTML: _vm._s(
                                              item.standardDescription
                                            )
                                          }
                                        })
                                      ]
                                    ),
                                    _c("td", { class: _vm.mobileRowClass }, [
                                      _vm.isMobileMode
                                        ? _c("div", {
                                            class: _vm.mobileHeaderClass
                                          })
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { class: _vm.mobileCellClass },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "mx-3 hover-button-primary",
                                              attrs: {
                                                color:
                                                  _vm.contentBreakpoint === "xs"
                                                    ? "primary"
                                                    : "transparent",
                                                text: "",
                                                small: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  return _vm.editSelectedStandard(
                                                    item
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("editLabel")) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "mx-3 hover-button-error",
                                              attrs: {
                                                color:
                                                  _vm.contentBreakpoint === "xs"
                                                    ? "error"
                                                    : "transparent",
                                                small: "",
                                                text: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  return _vm.deleteSelectedStandard(
                                                    item
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("deleteLabel")
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ])
                                  ]
                                )
                              }),
                              0
                            )
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.selectedRows,
                      callback: function($$v) {
                        _vm.selectedRows = $$v
                      },
                      expression: "selectedRows"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("pb-confirm", { ref: "standardsConfirm" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }